import IconOne from '@assets/images/icons/doswiadczenie-potwierdzone-referencjami.svg';
import IconTwo from '@assets/images/icons/szeroki-zakres-specjalizacji.svg';
import IconThree from '@assets/images/icons/wsparcie-w-jezyku-angielskim.svg';
import { t } from '@lingui/macro';
import React from 'react';
import { FC } from 'react';
import { ReactNode } from 'react';

const Highlights: FC = () => {
	return (
		<>
			<div className="py-8 md:py-12">
				<div className="justify-center items-center mb-8 text-center md:flex md:text-left md:mb-6 sm:flex-row sm:mb-12 sm:space-x-10">
					<h2 className="py-2 mb-2 font-serif text-2xl font-bold md:mb-0 sm:whitespace-nowrap">
						Zalety współpracy
					</h2>
				</div>
				<div className="grid gap-10 md:grid-cols-3 md:gap-20">
					<Highlight
						title={t`Doświadczenie potwierdzone referencjami`}
						icon={<IconOne className="w-full h-full" />}
						description={t`Zaufały nam duże firmy z branży telekomunikacyjnej, którym usługi prawne świadczymy od ponad 18 lat`}
					/>
					<Highlight
						title={t`Szeroki zakres specjalizacji wspierających biznes`}
						icon={<IconTwo className="w-full h-full" />}
						description={t`Telekomunikacja, prawo korporacyjne, prawo konsumenckie i wiele innych – potwierdzone wieloletnią praktyką`}
					/>
					<Highlight
						title={t`Wsparcie prawne także w języku angielskim`}
						icon={<IconThree className="w-full h-full" />}
						description={t`Negocjacje i analizy kontraktów od wielu lat prowadzimy również w języku angielskim`}
					/>
				</div>
			</div>
		</>
	);
};

export default Highlights;

type HighlightProps = {
	icon: ReactNode;
	title: string;
	description: string;
};

const Highlight: FC<HighlightProps> = ({ icon, title, description }) => {
	return (
		<>
			<div className="flex space-x-6 md:block md:space-x-0">
				<div className="flex-grow-0 flex-shrink my-auto w-20 md:mb-4 md:mx-auto md:w-24 md:h-32 sm:flex md:items-center">
					{icon}
				</div>
				<div className="md:text-center">
					<h3 className="mb-2 font-sans text-xs font-bold sm:mb-3 sm:text-sm">
						{title}
					</h3>
					<p className="font-sans text-xs sm:text-sm">{description}</p>
				</div>
			</div>
		</>
	);
};
