import { Link } from 'gatsby';
import React from 'react';
import { FC } from 'react';

type ListProps = {
	data: { label: string; link?: string }[];
};

const List: FC<ListProps> = ({ data }) => {
	return (
		<ul className="grid sm:grid-cols-2 gap-y-2 gap-x-6 w-full">
			{data.map((entry) => (
				<ListItem key={entry.label} label={entry.label} link={entry.link} />
			))}
		</ul>
	);
};

export default List;

type ListItemProps = {
	label: string;
	link?: string;
};

const ListItem: FC<ListItemProps> = ({ label, link }) => {
	return (
		<li className="flex items-center space-x-3">
			<div className="w-2 h-2 rounded-full border-2 border-blue" />
			{link ? (
				<Link to={link} className="hover:text-blue">
					{label}
				</Link>
			) : (
				<span>{label}</span>
			)}
		</li>
	);
};
