import Layout from '@components/layout/Layout';
import Container from '@components/ui/container/Container';
import Footer from '@components/ui/footer/Footer';
import Header from '@components/ui/header/Header';
import Highlights from '@components/ui/highlights/Highlights';
import List from '@components/ui/list/List';
import TitleBar from '@components/ui/title-bar/TitleBar';
import LanguageProvider from '@lib/language.provider';
import { i18n } from '@lingui/core';
import { t, Trans } from '@lingui/macro';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const IndexPage = ({ pageContext, location }) => {
	i18n.activate(pageContext.language);

	const specializations = [
		{
			label: t`Telekomunikacja`,
			link: '/specjalizacje/telekomunikacja'
		},
		{
			label: t`Fuzje i przejęcia`,
			link: '/specjalizacje/fuzje-i-przejecia'
		},
		{
			label: t`IPO i Corporate Governance`,
			link: '/specjalizacje/ipo-i-corporate-governance'
		},
		{
			label: t`Kontrakty cywilne i handlowe`,
			link: '/specjalizacje/kontrakty-cywilne-i-handlowe'
		},
		{
			label: t`Spółki i prawo korporacyjne`,
			link: '/specjalizacje/spolki-i-prawo-korporacyjne'
		},
		{
			label: t`IT i media`,
			link: '/specjalizacje/it-i-media'
		},
		{
			label: t`Prawo konsumenckie`,
			link: '/specjalizacje/prawo-konsumenckie'
		},
		{
			label: t`Ochrona danych osobowych`,
			link: '/specjalizacje/ochrona-danych-osobowych'
		},
		{
			label: t`E-Commerce`,
			link: '/specjalizacje/e-commerce'
		},
		{
			label: t`Prawo konkurencji`,
			link: '/specjalizacje/prawo-konkurencji'
		}
	];

	return (
		<LanguageProvider>
			<Layout>
				<main className="min-h-screen">
					<Header location={location.pathname} />
					<TitleBar>
						<div className="md:flex">
							<div className="relative flex-1 order-2">
								<div className="z-10 -mx-5 md:absolute md:ml-0 md:-mr-8 lg:-mr-10">
									<StaticImage
										className="block object-cover w-full h-60 md:h-2/3 max-h-640"
										placeholder={'none'}
										src={'../assets/images/home-photo-01.jpg'}
										alt={t`Iwanejko-Taczkowska - Legal & Advisory - Specjalizacje`}
									/>
								</div>
							</div>
							<div className="pt-10 text-white md:max-w-xl lg:max-w-2xl md:pr-20">
								<h1 className="mb-5 font-serif text-2xl md:text-3xl font-bold">
									<Trans>
										Wykorzystaj wieloletnie doświadczenie prawne{' '}
										<span className="whitespace-nowrap">w działaniu</span>{' '}
										Twojej firmy
									</Trans>
								</h1>
								<p className="mb-8">
									<Trans>
										Posiadamy szerokie kompetencje w obsłudze prawnej
										przedsiębiorców{' '}
										<span className="whitespace-nowrap">z </span>
										branży telekomunikacyjnej, potwierdzone tytułem Master of
										Business Administration zdobytym na studiach Executive MBA{' '}
										<span className="whitespace-nowrap">w Akademii </span>
										Leona Koźmińskiego.
									</Trans>
								</p>
								<Link
									to={'/kontakt'}
									className="py-4 px-5 text-sm font-bold text-white uppercase transition duration-300 bg-blue hover:bg-blue-shade"
								>
									Skontaktuj się
								</Link>
							</div>
						</div>
					</TitleBar>
					<Container>
						<div className="py-8 w-full md:max-w-xl lg:max-w-2xl md:py-12 text-gray-dark md:pr-20">
							<h1 className="mb-5 font-serif text-2xl font-bold">
								<Trans>Kompleksowa obsługa prawna</Trans>
							</h1>
							<p className="mb-8">
								<Trans>
									Naszym celem jest pełna realizacja interesów Klienta, z
									uwzględnieniem aspektów prawnych jak i biznesowych.
									Specjalizujemy się w następujących dziedzinach prawa:
								</Trans>
							</p>
							<div className="text-sm font-bold">
								<List data={specializations} />
							</div>
						</div>
					</Container>
					<div className="bg-gray-light">
						<Container>
							<Highlights />
						</Container>
					</div>
					<Footer background={false} />
				</main>
			</Layout>
		</LanguageProvider>
	);
};

export default IndexPage;
